<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'APP',
  methods: {
    ...mapActions({
      getVideos: 'videos/get',
    }),
  },
  mounted() {
    this.getVideos();
  },
};
</script>

<style lang="scss" scoped>

</style>
