<template>
  <div class="playerContainer">
    <div class="logoContainer">
    <img class="logo" :src="`${imageBase}/img/logo.png`" />
    </div>
    <video autoplay preload loop muted playsinline>
      <source :src="selectedVideo" type="video/mp4" />
    </video>
  <div class="player">

  </div>
  </div>

</template>

<script>
import vue from 'vue';

export default {
  name: 'introVideo',
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
      videos: [
        `${process.env.VUE_APP_IMG_BASE_URL}/intro/clip1.mp4`,
        `${process.env.VUE_APP_IMG_BASE_URL}/intro/clip2.mp4`,
        `${process.env.VUE_APP_IMG_BASE_URL}/intro/clip3.mp4`,
      ],
      selectedVideo: null,
    };
  },
  mounted() {
    vue.set(this, 'selectedVideo', this.videos[Math.floor(Math.random() * this.videos.length)]);
  },
};

</script>

<style lang="scss" scoped>
.playerContainer {
  position: relative;
}
video {
  width: 100vw;
  max-height: 70vh;
  object-fit: cover;
}
.logoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.logo {
  max-width: 40vw;
}

</style>
