<template>
  <div>
    <category v-for="(category, key) in videosByCategory"
      v-bind:key="key"
      :categoryName="key">
    </category>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import category from './category.vue';

export default {
  name: 'Categories',
  components: {
    category,
  },
  computed: {
    ...mapState({
      videosByCategory: (state) => state.videos.data.videosByCategory,
    }),
  },

};
</script>
