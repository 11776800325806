<template>
  <div class="playerControls" >
    <div class="logo"><router-link to="/"><img class="logo" :src="`${imageBase}/img/logo.png`" alt="tv preocupada" /></router-link></div>
    <div class="title" v-if="video">{{title}}</div>
    <div class="director" v-if="video">{{video.director}}</div>
    <router-link class="close" v-bind:style="{backgroundImage: `url('${imageBase}/img/times-solid.svg')`}" to="/"></router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'playerControls',
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
    };
  },
  computed: {
    ...mapState({
      videos: (state) => state.videos.data.videos,
    }),
    video() {
      return this.videos[this.videoId];
    },
    videoId() {
      return parseInt(this.$route.params.id, 10);
    },
    title() {
      let { title } = this.video;
      if (this.$route.params.episode) {
        title += ` — ${this.video.episodes[parseInt(this.$route.params.episode, 10) - 1].episode_title}`;
      }
      return title;
    },
  },
};

</script>

<style lang="scss" scoped>
.playerControls {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px; ;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  font-size: 1.3rem;
  z-index: 10;
  position: relative;
  width: 100%;
  background: black;
}

@media screen and (max-width: 640px) {
 .title,  {
   padding-left: 6px;
  font-size: 14px;
 }
 .director {
  font-size: 14px;
 }
}

@media screen and (max-width: 480px) {
  .playerControls {
    flex-direction: column;
  }
  .title, .director {
    padding-top: 15px;
  }
  .close {
    position: absolute;
    right: 0;
  }
}

.close {
  width: 30px;
  height: 30px;
  background-repeat:  no-repeat
}

.logo {
  max-width: 100px;
}
</style>
