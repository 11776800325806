<template>
  <img :src="`${src}`" :alt="alt" @load="loaded" @error="loaded"/>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ImageC',
  data() {
    return {
    };
  },
  props: {
    src: { required: true },
    alt: { required: false },
  },
  computed: {
    ...mapState({

    }),
  },
  methods: {
    loaded() {
      this.$emit('load', this.$el);
    },
  },
  mounted() {
  },
};

</script>
