<template>
  <div class="videoPlayer">
    <playerControls></playerControls>
    <player v-if="videosLoaded"></player>
    <category :categoryName="category"></category>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import player from '@/components/player.vue';
import category from '@/components/category.vue';
import playerControls from '@/components/playerControls.vue';

export default {
  name: 'VideoPlayer',
  components: { player, playerControls, category },
  data() {
    return {
      category: this.$route.params.category,
      videoId: this.$route.params.videoId,
    };
  },
  computed: {
    ...mapState({
      videosLoaded: (state) => state.videos.loaded,
    }),
  },

  mounted() {
  },
};
</script>
