<template>
  <div class="category" v-if="!isEmptyCategory">
    <div class="header">
    <router-link v-if="showGoBack" to="/"><img class="goBack" :src="`${imageBase}/img/angle-left-solid.svg`" alt="ir para o início"></router-link>
    <h2>{{categoryName}}</h2>
    <h2 v-if="isSeries"> — {{currentSeries.title}}</h2>
</div>
    <div class="videos">
      <template v-if="isSeries">
      <videoThumb v-for="(episode, k) in currentSeries.episodes"
        v-bind:key="k"
        :videoItem="videos[$route.params.id]"
        :episode="(k+1)"
        :category="categoryName">
      </videoThumb>
      </template>

      <template v-if="!isSeries">
      <videoThumb v-for="videoItem in filteredCategoryVideos"
        v-bind:key="videoItem.id"
        :videoItem=videoItem
        :category="categoryName">
      </videoThumb>
</template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import videoThumb from './videoThumb.vue';

export default {
  name: 'category',
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
    };
  },
  components: {
    videoThumb,
  },
  props: {
    categoryName: { required: true },
  },
  computed: {
    ...mapState({
      videos: (state) => state.videos.data.videos,
      newVideos: (state) => state.videos.data.newVideos,
      videosByCategory: (state) => state.videos.data.videosByCategory,
      searchTerm: (state) => state.search.searchTerm,
    }),
    currentSeries() {
      console.log(this.$route);
      if (this.$route.params.id) {
        const series = this.videos[parseInt(this.$route.params.id, 10)];
        if (typeof series === 'undefined') {
          return false;
        }
        return series;
      }
      return false;
    },
    categoryVideos() {
      if (this.$route.params.category === 'Novidades') {
        return this.newVideos;
      }
      return this.videosByCategory[this.categoryName];
    },
    isEmptyCategory() {
      if (Object.keys(this.filteredCategoryVideos).length === 0) {
        return true;
      }
      return false;
    },
    filteredCategoryVideos() {
      const videos = {};
      Object.keys(this.categoryVideos).forEach((k) => {
        const v = this.categoryVideos[k];
        console.log(v.title.toLowerCase(), this.searchTerm.toLowerCase(), v.title.toLowerCase().includes(this.searchTerm.toLowerCase()));
        if (v.title.toLowerCase().includes(this.searchTerm.toLowerCase()) || v.director.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          videos[k] = v;
        }
      });
      return videos;
    },
    showGoBack() {
      return this.$route.name !== 'Home';
    },
    isSeries() {
      if (typeof this.$route.params.episode !== 'undefined') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #353535;
  margin-bottom: 15px;
  margin-top: 30px;
}

.category {
  margin-bottom: 60px;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  z-index: 2;
  position: relative;
}
.goBack {
  width: 15px;
  margin-right: 2em;
}
h2 {
  display: inline-block;
  padding-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, 214px);
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-gap: 45px;

  padding: 0.5rem;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}
</style>
