import vue from 'vue';
import getVideoId from 'get-video-id';
import request from '../request';

let seen = [];
if (localStorage.getItem('seen') !== null) {
  seen = JSON.parse(localStorage.getItem('seen'));
}

function isNew(d) {
  if (typeof d === 'undefined') {
    return false;
  }
  if (d === '') {
    return false;
  }
  if (new Date(d) === 'Invalid Date') {
    return false;
  }
  const videoDate = new Date(d);
  const now = new Date();
  if (videoDate < now) {
    return false;
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    loaded: false,
    data: {
      videos: {},
      videosByCategory: {},
      newVideos: {},
    },
    seen,
  },
  mutations: {
    set(state, payload) {
      const videos = {};
      const videosByCategory = {};
      const newVideos = {};
      payload.body.forEach((item) => {
        videos[item.id] = item.acf;
        videos[item.id].id = item.id;
        if (typeof item.acf.url !== 'undefined') {
          videos[item.id].videoMeta = getVideoId(item.acf.url);
          if (videos[item.id].videoMeta.service === 'youtube' && !videos[item.id].videoThumbnail) {
            videos[item.id].videoThumbnail = `https://img.youtube.com/vi/${videos[item.id].videoMeta.id}/0.jpg`;
          }
        }
        if (item.acf.has_episodes) {
          item.acf.episodes.forEach((episode, k) => {
            videos[item.id].episodes[k].videoMeta = getVideoId(episode.episode_url);
            if (videos[item.id].episodes[k].videoMeta.service === 'youtube' && !videos[item.id].episodes[k].videoThumbnail) {
              videos[item.id].episodes[k].videoThumbnail = `https://img.youtube.com/vi/${videos[item.id].episodes[k].videoMeta.id}/0.jpg`;
            }
          });
        }
        // Videos by category
        if (typeof item.acf.categories !== 'undefined') {
          item.acf.categories.forEach((category) => {
            if (typeof videosByCategory[category] === 'undefined') {
              videosByCategory[category] = {};
            }
            videosByCategory[category][item.id] = item.acf;
          });
        }
        // New Videos
        if (isNew(item.acf.show_has_new_until)) {
          newVideos[item.id] = item.acf;
        }
      });
      vue.set(state.data, 'videos', videos);
      vue.set(state.data, 'videosByCategory', videosByCategory);
      vue.set(state.data, 'newVideos', newVideos);
    },
    setSeen(state, id) {
      if (!state.seen.includes(id)) {
        state.seen.push(id);
        localStorage.setItem('seen', JSON.stringify(state.seen));
      }
    },
    setLoaded(state, v) {
      vue.set(state, 'loaded', v);
    },
    /*
    setFromCache(state, payload) {
      if (payload.body !== null) {
        if (typeof state.data[payload.type] === 'undefined') {
          vue.set(state.data, payload.type, payload.body);
          Object.assign(state, { loaded: true });
        }
      }
    }, */
  },
  actions: {
    get(context) {
      context.commit('setLoaded', false);
      /*      localStorage.getItem(type, (err, value) => {
        if (err === null) {
          context.commit('setFromCache', { body: value, type });
        }
      }); */

      request.make({
        type: 'GET',
        url: `${context.rootState.apiURL}/video?per_page=500`,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('set', { body: res.body });
          }
        }
        context.commit('setLoaded', true);
      });
    },
    setSeen(context, id) {
      context.commit('setSeen', id);
    },
    /* getDetails(context, route) {
      localStorage.getItem(route.type, (err, value) => {
        if (err === null) {
          context.commit('setFromCache', { body: value, type: route.type });
        }
      });
      request.make({
        type: 'GET',
        url: `${context.rootState.apiURL}/${route.type}?slug=${route.slug}&per_page=100`,
        data: {},
      }, (err, res) => {
        if (res.status === 200) {
          if (typeof res.body !== 'undefined') {
            context.commit('set', { body: res.body, type: route.type });
            if (res.body.length === 0) {
              window.vue.$router.push('/notFound');
            }
          }
        }
      });
    }, */
  },
  getters: {},
};
