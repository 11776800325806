<template>
  <div class="search">
    <button @click="activateSearch()" class="searchButton" v-if="!isVisible"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
    <input v-if="isVisible" v-model="searchTerm" type="text" id="searchTerm" />
    <button v-if="isVisible" @click="close()" class="closeButton"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
</button>

  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Search',

  computed: {
    ...mapState({
      isVisible: (state) => state.search.isVisible,
    }),
    searchTerm: {
      get() {
        return this.$store.state.search.searchTerm;
      },
      set(t) {
        this.setSearchTerm(t);
      },
    },
  },
  methods: {
    ...mapActions({
      setSearchVisibility: 'search/setVisibility',
      setSearchTerm: 'search/setSearchTerm',
    }),
    activateSearch() {
      this.setSearchVisibility(true);
    },
    close() {
      this.setSearchVisibility(false);
    },
  },
};

</script>

<style lang="scss" scoped>
.search {
  padding-top: 16px;
  text-align: center;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchButton, .closeButton {
  background: black;
  border: 0;
  border-radius: 100px;
  margin-left: 16px;
  color: white;
  padding: 18px;
  font-size: 16px;
  font-weight: 300;
  svg {
    vertical-align: bottom;
    width: 24px;
  }
}

.searchButton {
}

.closeButton {
  min-width: 60px;
  min-height: 60px;
  svg {
    width: 16px;
  }
}
input {
  border: 0;
  border-radius: 100px;
  padding: 12px 32px 10px;

}

</style>
