import Vue from 'vue';
import Vuex from 'vuex';
import search from './search';
import videos from './videos';
import waitingForServer from './waitingForServer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiURL: 'https://tvpreocupada.com/wp-json/acf/v3',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    videos,
    waitingForServer,
    search,
  },
});
