import superagent from 'superagent';
import Throttle from 'superagent-throttle/dist/browser';

const throttle = new Throttle({
  active: true,
  rate: 40,
  ratePer: 10000,
});

const request = {
  make(options, cb) {
    const { type, url } = options;
    let data = {};
    if (typeof window.vue !== 'undefined') {
      window.vue.$store.dispatch('setWaitingForServer', true);
    }
    if (typeof options.data !== 'undefined') {
      ({ data } = options);
    }
    const req = superagent();
    req.method = type.toUpperCase();
    req.url = url;
    req.set('Content-Type', 'application/json');
    req.use(throttle.plugin(url));

    if (type.toUpperCase() === 'GET') {
      req.query(data);
    } else {
      req.send(data);
    }
    req.end((err, res) => {
      if (typeof window.vue !== 'undefined') {
        window.vue.$store.dispatch('setWaitingForServer', false);
      }
      if (res) {
        if (err) {
          if (res.status === 401 || res.status === 403) {
            if (typeof window.vue.$router !== 'undefined') {
              if (window.vue.$route.name !== 'login') {
                window.vue.$router.push({ name: 'login', query: { fromUnauthorized: true } });
              }
            }
          } else if (res.status === 500) {
            const message = `${window.vue.t('INTERNAL_SERVER_ERROR_MESSAGE_PREFIX')} ${process.env.VUE_APP_ASSISTANCE_EMAIL} ${window.vue.t('INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX')}.`;
            const debugMessage = '';
            const timeout = 15000;
            window.vue.$store.dispatch('globalMessage/set', {
              message,
              debugMessage,
              className: 'danger',
              timeout,
            });
          } else if (res.status === 503) {
            window.location.reload(1);
          }
        }
        cb(err, res);
      }
    });
  },
};

export default request;
