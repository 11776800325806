<template>
  <router-link :to="videoLink"
    class="videoThumbnails" v-bind:class="{hasEpisodes: videoItem.has_episodes && !$route.params.episode, isActive: isCurrentVideo, isLoaded: isLoaded}">
    <div class="isNew" v-if="isNew(videoItem.show_has_new_until)">Novo</div>
    <div class="wasSeen" v-if="wasSeen" v-bind:style="{backgroundImage: `url('${imageBase}/img/eye-solid.svg')`}"></div>
    <template v-if="!videoItem.has_episodes || $route.params.episode">
    <ImageC class="videoThumbnail" :src="getThumbnail(videoItem)" v-on:load="isLoaded = true"/>
    </template>
    <template v-else>
      <ImageC class="videoThumbnail" :src="getThumbnail(videoItem.episodes[0])" v-on:load="isLoaded = true"/>
      <ImageC v-if="videoItem.episodes[1]"
        class="videoThumbnail"
        :src="getThumbnail(videoItem.episodes[1])"
        v-on:load="isLoaded = true"
      />
      <ImageC v-if="videoItem.episodes[2]"
        class="videoThumbnail"
        :src="getThumbnail(videoItem.episodes[2])"
        v-on:load="isLoaded = true"
      />
    </template>
    <h3>{{title}}</h3>

  </router-link>
</template>

<script>
import ImageC from './ImageC.vue';

export default {
  name: 'videoThumb',
  components: {
    ImageC,
  },
  props: {
    videoItem: { required: true },
    category: { required: true },
    episode: { required: false, default: 1 },
  },
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
      isLoaded: false,
    };
  },
  computed: {
    videoLink() {
      if (this.videoItem.has_episodes) {
        return { name: 'VideoPlayer', params: { category: this.category, id: this.videoItem.id, episode: this.episode } };
      }
      return { name: 'VideoPlayer', params: { category: this.category, id: this.videoItem.id } };
    },
    isCurrentVideo() {
      if (typeof this.$route.params.id === 'undefined') {
        return false;
      }
      if (this.videoItem.id === parseInt(this.$route.params.id, 10)) {
        return true;
      }
      return false;
    },
    wasSeen() {
      return this.$store.state.videos.seen.includes(this.videoItem.id);
    },
    title() {
      if (this.$route.params.episode) {
        return this.videoItem.episodes[this.episode - 1].episode_title;
      }
      return this.videoItem.title;
    },
  },
  methods: {
    isNew(d) {
      if (typeof d === 'undefined') {
        return false;
      }
      if (d === '') {
        return false;
      }
      if (new Date(d) === 'Invalid Date') {
        return false;
      }
      const videoDate = new Date(d);
      const now = new Date();
      if (videoDate < now) {
        return false;
      }
      return true;
    },
    getThumbnail(videoItem) {
      let thumbnail;
      if (typeof videoItem.thumbnail !== 'undefined') {
        thumbnail = videoItem.thumbnail;
      } else {
        thumbnail = videoItem.videoThumbnail;
      }
      if (this.$route.params.episode) {
        thumbnail = videoItem.episodes[this.episode - 1].videoThumbnail;
      }
      if (typeof thumbnail !== 'string') {
        return thumbnail.url;
      }

      return thumbnail;
    },
  },
};
</script>

<style lang="scss" scoped>

.videoThumbnails {
  position: relative;
  background: black;
  border-radius: 0 0 7px 7px;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.8);
  text-decoration:none;
  text-align: center;
  transition: all 1s;
  opacity: 0;
  transform: scale(0.2);
}
.videoThumbnails.isLoaded {
  opacity: 1;
  transform: scale(1);
}

.wasSeen {
  content: " ";
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  z-index: 4;
}

.isNew {
  position: absolute;
  top: -5px;
  left: -5px;
  background: #bb2222;
  padding: 4px 17px;
  color: white;
  z-index: 4;
}

.videoThumbnails.isActive:before {
  content: " ";
  position: absolute;
  box-shadow: 0px 0px 20px 0px rgba(255,255,255,0.8);
  width: 100%;
  height: 100%;
  left: 0;
}

.videoThumbnail {
  margin-left: -12px;
  max-width: 214px;
  object-fit: fill;
  border-radius: 7px;
}

.hasEpisodes .videoThumbnail:nth-child(1) {
  position: relative;
  z-index: 3;
  left: -8px;
  top: -8px;
  outline: 1px solid rgba(255,255,255,0.1);
}

.hasEpisodes .videoThumbnail:nth-child(2) {
  position:absolute;
  z-index: 2;
  left: 9px;
  top: 0px;
  outline: 1px solid rgba(255,255,255,0.1);
}

.hasEpisodes .videoThumbnail:nth-child(3) {
  position:absolute;
  z-index: 1;
  left: 14px;
  top: 8px;
  outline: 1px solid rgba(255,255,255,0.1);
}

h3 {
  color: #e3e3e3;
  font-size: 14px;
  padding-bottom: 16px;
}
</style>
