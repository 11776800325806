<template>
  <div class="home">
    <div class="logoContainer">
      <introVideo></introVideo>
    </div>
    <search v-if="videosLoaded"></search>
    <loading v-if="!videosLoaded"></loading>
    <newVideos v-if="!isSearchVisible"></newVideos>
    <categories class="categories" />

  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
import categories from '../components/categories.vue';
import newVideos from '../components/newVideos.vue';
import introVideo from '../components/introVideo.vue';
import search from '../components/search.vue';
import loading from '../components/loading.vue';

export default {
  name: 'Home',
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
    };
  },
  components: {
    categories, newVideos, introVideo, search, loading,
  },
  computed: {
    ...mapState({
      isSearchVisible: (state) => state.search.isVisible,
      videosLoaded: (state) => state.videos.loaded,
    }),
  },
  methods: {
    ...mapActions({
      getVideos: 'videos/get',
    }),
  },
  mounted() {
    this.getVideos();
  },
};
</script>

<style lang="scss" scoped>

.logoContainer {
  background: black;
  text-align: center;
  position: relative;
}
/*.logoContainer:after {
  content: " ";
  width: 100%;
  height: 40vh;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
}*/
.categories {

}

</style>
