<template>
  <div class="category" v-if="Object.keys(newVideos).length > 0">
    <div class="header">
      <router-link v-if="showGoBack" to="/"><img class="goBack" :src="`${imageBase}/img/angle-left-solid.svg`" alt="ir para o início"></router-link>
      <h2>Novidades</h2>
      <h2 v-if="isSeries"> — {{currentSeries.title}}</h2>
    </div>
    <div class="videos">
      <videoThumb v-for="videoItem in newVideos"
                  v-bind:key="videoItem.id"
                  :videoItem=videoItem
                  :category="'Novidades'">
      </videoThumb>
    </div>
  </div>
  </template>

<script>
import { mapState } from 'vuex';
import videoThumb from './videoThumb.vue';

export default {
  name: 'newVideos',
  data() {
    return {
      imageBase: process.env.VUE_APP_IMG_BASE_URL,
    };
  },
  components: {
    videoThumb,
  },
  props: {
  },
  computed: {
    ...mapState({
      videos: (state) => state.videos.data.videos,
      newVideos: (state) => state.videos.data.newVideos,
    }),
    currentSeries() {
      if (this.$route.params.id) {
        const series = this.videos[parseInt(this.$route.params.id, 10)];
        if (typeof series === 'undefined') {
          return false;
        }
        return series;
      }
      return false;
    },
    showGoBack() {
      return this.$route.name !== 'Home';
    },
    isSeries() {
      if (typeof this.$route.params.episode !== 'undefined') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #353535;
  margin-bottom: 15px;
  margin-top: 30px;
}

.category {
  margin-bottom: 60px;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  z-index: 2;
  position: relative;
}
.goBack {
  width: 15px;
  margin-right: 2em;
}
h2 {
  display: inline-block;
  padding-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, 214px);
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-gap: 45px;

  padding: 0.5rem;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}
</style>
