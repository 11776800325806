import vue from 'vue';

export default {
  namespaced: true,
  state: {
    isVisible: false,
    searchTerm: '',
  },
  mutations: {
    setVisibility(state, v) {
      vue.set(state, 'isVisible', v);
    },
    setSearchTerm(state, t) {
      vue.set(state, 'searchTerm', t);
    },
  },
  actions: {
    setVisibility(context, v) {
      context.commit('setVisibility', v);
      if (!v) {
        context.commit('setSearchTerm', '');
      }
    },
    setSearchTerm(context, t) {
      context.commit('setSearchTerm', t);
    },
  },

};
