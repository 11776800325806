<template>
  <div class="playerContainer">
  <div class="player">
    <template v-if="service === 'youtube'">
      <iframe width="100%" height="100%" :src="`https://www.youtube.com/embed/${serviceId}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </template>
    <template v-else-if="service === 'vimeo'">
      <iframe :src="`https://player.vimeo.com/video/${serviceId}`" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
   </template>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Player',
  data() {
    return {
      seenTimeout: null,
    };
  },
  computed: {
    ...mapState({
      videos: (state) => state.videos.data.videos,
    }),
    videoId() {
      return parseInt(this.$route.params.id, 10);
    },
    service() {
      if (typeof this.videos[this.videoId] === 'undefined') {
        return false;
      }
      if (this.videos[this.videoId].has_episodes) {
        return this.videos[this.videoId].episodes[this.episodeNr - 1].videoMeta.service;
      }
      return this.videos[this.videoId].videoMeta.service;
    },
    episodeNr() {
      let episodeNr = 1;
      if (this.$route.params.episode) {
        episodeNr = parseInt(this.$route.params.episode, 10);
      }
      return episodeNr;
    },
    video() {
      return this.videos[this.videoId];
    },
    serviceId() {
      if (typeof this.videos[this.videoId] === 'undefined') {
        return false;
      }
      if (this.videos[this.videoId].has_episodes) {
        return this.videos[this.videoId].episodes[this.episodeNr - 1].videoMeta.id;
      }
      return this.videos[this.videoId].videoMeta.id;
    },
  },
  watch: {
    videoId() {
      clearTimeout(this.seenTimeout);
      this.seenTimeout = setTimeout(() => {
        this.setSeen(parseInt(this.$route.params.id, 10));
      }, 15000);
    },
  },
  methods: {
    ...mapActions({
      setSeen: 'videos/setSeen',
    }),
  },
  destroyed() {
    clearTimeout(this.seenTimeout);
  },
  mounted() {
    this.seenTimeout = setTimeout(() => {
      this.setSeen(parseInt(this.$route.params.id, 10));
    }, 15000);
  },
};

</script>

<style lang=scss scoped>
 .player {
    height: calc(100vh - 200px);
    background: black;
 }

</style>
